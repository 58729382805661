import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import parse from "html-react-parser"

const ButtonLink = function ({ item }) {
  const btnColor = item.btnColor ? `btn ${item.btnColor}` : `btn btn-primary`
  return (
    <Link className={btnColor} to={item.link}>
      {item.icon && <i className={item.icon} />}
      {parse(item.label)}
    </Link>
  )
}

ButtonLink.propTypes = {
  item: PropTypes.shape({
    btnColor: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
}

export default ButtonLink
