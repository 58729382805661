import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

const Banner = function ({ background, content }) {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      defaultBannerBG: file(relativePath: { eq: "banner/bg-default.jpg" }) {
        publicURL
      }
    }
  `)

  const bg = background ? background.publicURL : data.defaultBannerBG.publicURL
  return (
    <section
      className="py-20 bg-cover bg-no-repeat bg-top"
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <div className="container">
        <div>{parse(content)}</div>
      </div>
    </section>
  )
}

Banner.propTypes = {
  background: PropTypes.string,
  content: PropTypes.string.isRequired,
}

Banner.defaultProps = {
  background: ``,
}

export default Banner
