import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

const PartnerLogo = function ({ item }) {
  return (
    <li>
      <a href={item.link} target="_blank" rel="noopener noreferrer">
        <GatsbyImage
          image={item.logo.childImageSharp.gatsbyImageData}
          alt={item.name}
        />
      </a>
    </li>
  )
}

PartnerLogo.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
}

export default PartnerLogo
