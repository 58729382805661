import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import parse from "html-react-parser"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Banner from "../../components/Banner"
import ButtonLink from "../../components/ButtonLink"
import PartnerLogo from "../../components/PartnerLogo"

import PresentationAteliers from "../../images/video-presentation-ateliers-ensemble-au-micro-one-station.mp4"

const EnsembleAuMicroPage = function ({
  data: { ensembleAuMicroJson },
  location,
}) {
  return (
    <Layout>
      <Seo
        title={ensembleAuMicroJson.title}
        description={ensembleAuMicroJson.description}
        pathname={location.pathname}
      />
      <Banner
        background={ensembleAuMicroJson.banner.background}
        content={ensembleAuMicroJson.banner.content}
      />
      <div className="container">
        <section>
          {parse(ensembleAuMicroJson.title1)}
          {parse(ensembleAuMicroJson.content1)}
          {/* <ButtonLink item={ensembleAuMicroJson.button1} /> */}
          {parse(ensembleAuMicroJson.title2)}
          {parse(ensembleAuMicroJson.content2)}
          <div className="text-center mb-10">
            <ButtonLink item={ensembleAuMicroJson.button2} />
          </div>
          <h2 className="normal-case text-center">
            Vidéo de présentation des ateliers
          </h2>
          <div className="text-center max-w-4xl mx-auto">
            <video controls>
              <source src={PresentationAteliers} type="video/mp4" />
            </video>
          </div>

          {/* <ul className="grid md:grid-cols-3 md:gap-5">
            {ensembleAuMicroJson.partners.map(function (item) {
              return <PartnerLogo key={uuidv4()} item={item} />
            })}
          </ul> */}
        </section>
      </div>
    </Layout>
  )
}

EnsembleAuMicroPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default EnsembleAuMicroPage

export const query = graphql`
  query EnsembleAuMicroPageQuery {
    ensembleAuMicroJson {
      title
      description
      banner {
        background {
          publicURL
        }
        content
      }
      title1
      content1
      button1 {
        label
        link
      }
      title2
      content2
      button2 {
        label
        link
      }
      partners {
        name
        logo {
          childImageSharp {
            gatsbyImageData
          }
        }
        link
      }
    }
  }
`
